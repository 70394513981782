import React from "react"
import Helmet from "react-helmet";

import { graphql } from "gatsby"

import DefaultPage from "../templates/default-page";
import Image from '../components/image/cmsImage'
import Social from "../components/social";
import AvailableNow from "../components/available-now";
import Footer from "../components/footer";
import NewsCard from "../components/news-card";

const getRelated = (thisPost: NewsPost, allPosts: Array<GraphEdge<NewsPost>>) => {
    const relatedArticles = thisPost.frontmatter.relatedArticles || []
    const postPath = thisPost.frontmatter.path

    const related = relatedArticles
    .map(articlePath => allPosts.filter((article) => article.node.frontmatter.path === articlePath))
    .filter((p) => p && p.length > 0)
    .map((p) => p[0])
    .filter(p => {
        const {draft, path} = p.node.frontmatter
        return !draft && path !== postPath
    })

    const recent = allPosts
    .filter(p => {
        const {draft, path} = p.node.frontmatter
        return !draft && path !== postPath && !relatedArticles.includes(path)
    })

    return [...related, ...recent].slice(0, 3)
}

interface PostTemplateProps {
    post: NewsPost
    content: string
    homepage: {
        edges: Array<GraphEdge<HomePageCopy>>
    },
    allArticles: Array<GraphEdge<NewsPost>>
}
interface PostTemplateState {
    isShowLetterbox: boolean
    letterboxItem: any[] | null
    isScrolling: boolean
    isScrollingPastHeader: boolean
    progress: 0 | string,
    heroStyle: any,
    progressStyle: any
}

export class PostPageTemplate extends React.Component<PostTemplateProps, PostTemplateState> {
    constructor(props: any) {
        super(props)
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', () => this.onScroll())
        }
        this.state = {
            isShowLetterbox: false,
            letterboxItem: [],
            isScrolling: false,
            isScrollingPastHeader: false,
            progress: 0,
            heroStyle: {},
            progressStyle: {}
        }
    }

    showPhoto(item: any) {
        this.setState({ isShowLetterbox: true, letterboxItem: item })
    }

    onLetterboxClose() {
        this.setState({ isShowLetterbox: false, letterboxItem: null })
    }

    onScroll() {
        const isScrolling = window.scrollY > 0
        const isScrollingPastHeader = window.scrollY > (300 - 96)
        const headingHeight = 300 - window.scrollY
        const hasHeadingHeight = headingHeight < 300 && 
            headingHeight > 96 && 
            window.innerWidth > 991
        const progressHeight = headingHeight + 96


        const progress = ((window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100) + '%'
        this.setState({ isScrolling: isScrolling, isScrollingPastHeader: isScrollingPastHeader, progress: progress })

        if (hasHeadingHeight) {
            this.setState({ heroStyle: { height: headingHeight + 'px' },
                progressStyle: { top: progressHeight + 'px' } })
        } else {
            this.setState({ heroStyle: {}, progressStyle: {} })
        }
    }

    render() {
        const { post, allArticles, content, homepage } = this.props
        const {title, image, date, tags, author, path}  = post.frontmatter
        
        const copy = homepage.edges[0].node.frontmatter
        const related = getRelated(post, allArticles)

        let heroClass = 'c-post-hero';
        // heroClass += this.state.isScrolling ? ' scrolling' : '';

        let heroScrollerClass = 'c-post-hero-scroller';
        heroScrollerClass += this.state.isScrollingPastHeader ? ' scrolling' : '';

        let containerClass = 'c-news-post-container'
        containerClass += this.state.isScrolling ? ' scrolling' : '';

        let progressClass = 'c-progress-element'
        progressClass += this.state.isScrolling ? ' scrolling' : '';
        progressClass += this.state.isScrollingPastHeader ? ' scrolling-desktop' : '';

        const pageUrl = `http://www.frostfall.com/${path}`
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`
        const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent("Frostfall")}&url=${encodeURIComponent(pageUrl)}`
        const mailUrl = `mailto:?&body=${pageUrl}`

        return (
            <DefaultPage isIndex={false} isNewsPost={true} isShowLetterbox={this.state.isShowLetterbox} onLetterboxClose={() => this.onLetterboxClose()} letterboxItem={this.state.letterboxItem}>
                <Helmet>
                    <title>Frostfall - {title}</title>
                    <meta name="description" content={`Frostfall - ${title}`} />
                </Helmet>
                <div className={containerClass}>
                    <div className={heroClass}>
                    {/* <div className={heroClass} style={this.state.heroStyle}> */}
                        {/* <div className="o-tags">
                        {(tags || []).map((tag) => <span className="o-tag">{tag}</span>)}
                        </div>  */}
                        <h2 className="c-post-hero__heading">{title}</h2>
                        <h4 className="c-post-hero__date">{date}</h4>
                        <Image className="c-post-hero__image-wrapper"
                            alt="Hero Image"
                            src={image}
                            background={{
                                backgroundX: 'left',
                                backgroundY: 'center',
                                cover: true,
                                zIndex: -5
                            }}
                        />
                    </div>
                    <div className={heroScrollerClass}>
                        <h2 className="c-post-hero-scroller__heading">{title}</h2>
                        <Image className="c-post-hero__image-wrapper"
                            alt="Hero Image"
                            src={image}
                            background={{
                                backgroundX: 'left',
                                backgroundY: 'center',
                                cover: true,
                                zIndex: -5
                            }}
                        />
                    </div>
                    <div className={progressClass} style={this.state.progressStyle}><span className="c-progress-element__progress" style={{width: this.state.progress}}></span></div>
                    <div
                    className="news-post-content"
                    dangerouslySetInnerHTML={{ __html: content }}
                    />
                    <div className="c-post-footer">
                        <hr />
                        <div className="row c-post-footer__contents">
                        <div className="c-post-author">
                            <div className="c-post-author__name">Written by {author}</div>
                            <div className="c-post-author__date">{date}</div>
                        </div>
                        <div className="c-post-share">
                            <h4>Share</h4>
                            <div className="c-post-share__icons">
                            <a href={facebookUrl} target="_blank"><span className="o-social-item__icon mini facebook"/></a>
                            <a href={twitterUrl} target="_blank"><span className="o-social-item__icon mini twitter"/></a>
                            <a href={mailUrl} target="_blank"><span className="o-social-item__icon mini email"/></a>
                            </div>
                        </div>
                        </div>
                        <div className="c-post-related-articles">
                        <h2 className="c-post-related-articles__heading">Related Articles</h2>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <ul className="o-card__container row">
                        {related.map((post) => <NewsCard post={post} key={post.node.id} />)}
                        </ul>
                    </div>
                </div>

                <Social headerText={copy.socialHeading} subtitleText={copy.socialSubtitle}/>
                {/* <AvailableNow headerText={copy.downloadHeading} /> */}
                <Footer />
            </DefaultPage>
        )
    }
}

const PostPage = ({data}: NewsPostPageQuery) => {
    return <PostPageTemplate
        content={data.markdownRemark.html}
        post={data.markdownRemark}
        allArticles={data.allArticles.edges}
        homepage={data.homepage}
    />
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MM | DD | YYYY")
        path
        author
        title
        image
        tags
      }
    },
    homepage: allMarkdownRemark(filter: {fields:{sourceName: {eq: "homepage"}}}) {
      edges {
        node {
          frontmatter {
            ctaHeading
            ctaSubtitle
            ctaButtonText
            newsHeading
            newsArchiveButtonText
            mediaHeading
            mediaArchiveButtonText
            socialHeading
            socialSubtitle
            downloadHeading
          }
        }
      }
    },
    allArticles: allMarkdownRemark(filter: {fields:{sourceName: {eq: "news"}}}, sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          fields {
            sourceName
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MM | DD | YYYY")
            path
            draft
            tags
            title
            image
            popoverContent
          }
        }
      }
    }, 
  }
`

export default PostPage